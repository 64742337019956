import { useTranslation } from 'gatsby-plugin-react-i18next';
import InfoBox from 'ui-kit/info-box/info-box';
import Button from 'ui-kit/button/button';
import UserCircle from 'ui-kit/icons/user-circle';
import { navigate } from 'gatsby';

import './manage-family-banner.style.scss';

const ManageFamilyBanner = () => {
    const { t } = useTranslation();
    return (
        <div className="manage-family-banner">
            <InfoBox
                buttonPosition="right"
                icon={<UserCircle />}
                button={
                    <Button
                        className="my-md-0 sm-full btn-bold btn-outline"
                        label={t('components.familyManagement.banner.link')}
                        type="button"
                        onClick={() => {
                            navigate('/secure/profile/family-account');
                        }}
                        dataGALocation="MedicineCabinet"
                        variant="outline-primary"
                    />
                }
            >
                <>
                    <h4>{t('components.familyManagement.banner.title')}</h4>
                    <p>{t('components.familyManagement.banner.body')}</p>
                </>
            </InfoBox>
        </div>
    );
};

export default ManageFamilyBanner;
