import React from 'react';
import { OrderTrackerProps } from './types';
import { useRxSubStatus } from 'hooks/useRxSubStatus';
import './index.style.scss';
import { CircleCheck } from 'components/circle-check';

export const OrderTracker: React.FC<OrderTrackerProps> = ({ rx }) => {
    const { CurrentStep, MaxStep } = rx.rxSubStatus;
    const { orderTrackerStepDisplayNames } = useRxSubStatus(rx);

    const orderStepArray = Array.from({ length: MaxStep }, (_, i) => i + 1);

    return (
        <div className="rx-order-tracker__container">
            <ul className="rx-order-tracker__list">
                <li className="rx-order-tracker__list-item" key={0}>
                    <div className="rx-order-tracker__list-item-status">
                        <CircleCheck />
                        <div className="rx-order-tracker__separator rx-order-tracker__current-step" />
                    </div>
                    <span className="rx-order-tracker__list-item-display-label">Ordered</span>
                </li>

                {orderStepArray.map((step, index) => {
                    const displayLabel = orderTrackerStepDisplayNames.find(
                        (trackerStep) => trackerStep.currentStep === step
                    );

                    if (displayLabel) {
                        return (
                            <li key={step} className="rx-order-tracker__list-item">
                                <div className="rx-order-tracker__list-item-status">
                                    {CurrentStep <= index + 1 && CurrentStep !== orderStepArray.length ? (
                                        <div
                                            className={`rx-order-tracker__list-item-circle ${
                                                CurrentStep === step && 'rx-order-tracker__current-step'
                                            }`}
                                        >
                                            &nbsp;
                                        </div>
                                    ) : (
                                        <CircleCheck />
                                    )}

                                    <div
                                        className={`rx-order-tracker__separator ${
                                            step < CurrentStep && 'rx-order-tracker__current-step'
                                        }`}
                                    />
                                </div>
                                <div className={`rx-order-tracker__list-item-display-container`}>
                                    <span
                                        className={`rx-order-tracker__list-item-display-label ${
                                            CurrentStep === step && 'rx-order-tracker__current-step-display'
                                        }`}
                                    >
                                        {displayLabel.title}
                                    </span>
                                    {displayLabel.subStatus && (
                                        <span
                                            className={`rx-order-tracker__list-item-display-label-substatus ${
                                                CurrentStep === step && 'rx-order-tracker__current-step-display'
                                            }`}
                                        >
                                            {displayLabel.subStatus}
                                        </span>
                                    )}
                                </div>
                            </li>
                        );
                    }
                    return <></>;
                })}
            </ul>
        </div>
    );
};
