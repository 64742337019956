import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useMemo } from 'react';

import { PrescriptionObjectPayload } from 'state/medicine-cabinet/medicine-cabinet.services';

import { getPhoneNumber } from 'util/globalVariables';
import { CURRENT_STEP_DESCRIPTION, RX_SUB_STATUS } from 'util/prescription';

export const useRxSubStatus = (rx: PrescriptionObjectPayload) => {
    const { t } = useTranslation();
    const { rxSubStatus } = rx;

    const orderTrackerStepDisplayNames = [
        {
            title: t('components.prescriptionCard.orderStatuses.processing'),
            currentStep: CURRENT_STEP_DESCRIPTION.PROCESSING
        },
        {
            title: t('components.prescriptionCard.orderStatuses.fillingInPharmacy'),
            currentStep: CURRENT_STEP_DESCRIPTION.FILLING_IN_PHARMACY,
            subStatus: t('components.prescriptionCard.orderStatuses.fillingInPharmacySubStatus')
        },
        {
            title: t('components.prescriptionCard.orderStatuses.packaged'),
            currentStep: CURRENT_STEP_DESCRIPTION.PACKAGED,
            subStatus: t('components.prescriptionCard.orderStatuses.packagedSubStatus')
        },
        {
            title: t('components.prescriptionCard.orderStatuses.shipped'),
            currentStep: CURRENT_STEP_DESCRIPTION.SHIPPED,
            subStatus: t('components.prescriptionCard.orderStatuses.shippedSubStatus')
        },
        {
            title: t('components.prescriptionCard.orderStatuses.outForDelivery'),
            currentStep: CURRENT_STEP_DESCRIPTION.OUT_FOR_DELIVERY
        },
        {
            title: t('components.prescriptionCard.orderStatuses.delivered'),
            currentStep: CURRENT_STEP_DESCRIPTION.DELIVERED
        }
    ];

    const getOrderSubStatus = (inProgressStatus: number) => {
        switch (inProgressStatus) {
            case RX_SUB_STATUS.PLEASE_CALL_BIRDI:
                return t('components.prescriptionCard.orderStatuses.processingSubStatus.followUpRequired');
            case RX_SUB_STATUS.AUTH_REQUIRED:
                return t('components.prescriptionCard.orderStatuses.processingSubStatus.priorAuthRequired');
            case RX_SUB_STATUS.WAITING_ON_NEW_RX:
                return t('components.prescriptionCard.orderStatuses.processingSubStatus.waitingOnNewRx');
            default:
                return;
        }
    };

    const isOrderProcessing = useMemo(() => {
        return rxSubStatus && rxSubStatus.CurrentStep === CURRENT_STEP_DESCRIPTION.PROCESSING;
    }, [rxSubStatus]);

    const getRxSubStatus = () => {
        // if no rx sub status, just return
        if (
            !rxSubStatus ||
            rxSubStatus.CurrentStep === CURRENT_STEP_DESCRIPTION.UNKNOWN ||
            !rxSubStatus.RxInProgressStatus
        )
            return { orderStatus: '' };

        if (isOrderProcessing) {
            const subStatus = getOrderSubStatus(rxSubStatus.RxInProgressStatus);

            return {
                orderStatus: t('components.prescriptionCard.orderStatuses.processing'),
                subStatus: subStatus,
                footNote: t('components.prescriptionCard.orderStatuses.processingSubStatus.callBirdiMessage', {
                    phoneNumber: getPhoneNumber({ isEnd: true })
                })
            };
        }

        if (rxSubStatus.CurrentStep === CURRENT_STEP_DESCRIPTION.UNKNOWN) {
            return {
                orderStatus: null
            };
        }

        if (rxSubStatus.CurrentStep === CURRENT_STEP_DESCRIPTION.FILLING_IN_PHARMACY) {
            return {
                orderStatus: t('components.prescriptionCard.orderStatuses.fillingInPharmacy'),
                subStatus: t('components.prescriptionCard.orderStatuses.fillingInPharmacySubStatus')
            };
        }

        if (rxSubStatus.CurrentStep === CURRENT_STEP_DESCRIPTION.PACKAGED) {
            return {
                orderStatus: t('components.prescriptionCard.orderStatuses.packaged'),
                subStatus: t('components.prescriptionCard.orderStatuses.packagedSubStatus')
            };
        }

        if (rxSubStatus.CurrentStep === CURRENT_STEP_DESCRIPTION.SHIPPED) {
            return {
                orderStatus: t('components.prescriptionCard.orderStatuses.shipped'),
                subStatus: t('components.prescriptionCard.orderStatuses.shippedSubStatus')
            };
        }

        if (rxSubStatus.CurrentStep === CURRENT_STEP_DESCRIPTION.OUT_FOR_DELIVERY) {
            return { orderStatus: t('components.prescriptionCard.orderStatuses.outForDelivery') };
        }
        if (rxSubStatus.CurrentStep === CURRENT_STEP_DESCRIPTION.DELIVERED) {
            return { orderStatus: t('components.prescriptionCard.orderStatuses.delivered') };
        }
        return { orderStatus: '' };
    };

    return { getRxSubStatus, isOrderProcessing, orderTrackerStepDisplayNames };
};
