export type PrescriptionsCardsProps = {
    children: React.ReactNode;
    isCollapsed: boolean;
};

const PrescriptionsCards: React.FC<PrescriptionsCardsProps> = ({ children, isCollapsed }) => {
    return <div className={`prescription-list__cards ${isCollapsed ? 'collapsed' : ''}`}>{children}</div>;
};

export default PrescriptionsCards;
