import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import PrescriptionCardModal from 'components/prescriptions-list-v2/prescription-card-modal/prescription-card-modal';

import {
    accountDefaultAddressSelector,
    accountFamilyPlansMapSelector,
    accountHasInsuranceSelector,
    accountIsCaliforniaUserSelector,
    accountProfileSelector,
    accountProfilIsCaregiverSelector
} from 'state/account/account.selectors';
import { cancelPrescriptionRoutine } from 'state/add-transfer-prescription/add-transfer-prescription.routines';
import { closeModal, openModal, setBusyModal } from 'state/birdi-modal/birdi-modal.reducers';
import { cartOrderShippingAddressSelector } from 'state/cart/cart.selectors';
import { DrugWithDiscountPrice } from 'state/drug/drug.reducers';
import { drugSelector } from 'state/drug/drug.selectors';
import { medicineCabinetGetAllPrescriptions } from 'state/medicine-cabinet/medicine-cabinet.routines';
import { medicineCabinetAutoRefillToggleBusySelector } from 'state/medicine-cabinet/medicine-cabinet.selectors';
import { membershipIsOnDemandSelector } from 'state/membership/membership.selector';

import { formatPrice } from 'schema/price.schema';

import { getPhoneNumber } from 'util/globalVariables';
import { TrackViewItem, ViewItemType } from 'util/google_optimize/optimize_helper';
import { CURRENT_STEP_DESCRIPTION, hasPlanAutoRefillFlag } from 'util/prescription';

import { useRxSubStatus } from 'hooks/useRxSubStatus';

import './prescription-card.style.scss';
import PrescriptionCardDesktop from './prescriptions-card-desktop';
import PrescriptionCardMobile from './prescriptions-card.mobile';
import { PrescriptionCardProps } from './types';

function findPriceMatch(rxNumber: string, zipcode: string, drugDiscountPrices: DrugWithDiscountPrice[]) {
    // Check if the price exists for the given prescription number.
    const priceMatches = drugDiscountPrices.filter((item) => {
        return item.rxNumber === rxNumber && item.zipCode === zipcode;
    });

    if (priceMatches.length > 0) {
        return priceMatches[0].price;
    }

    return '';
}

const PrescriptionCard: React.FC<PrescriptionCardProps> = ({
    isCollapsed,
    fullRxItem,
    prescriptionName,
    orderStatus,
    refillsLeft,
    rxNumber,
    rxSeqNum,
    details = [],
    statuses = [],
    ctas = [],
    isAddingToCart,
    autoRefillToggle,
    footNote,
    planAlias,
    isBirdiSelect
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { drugDiscountPrices } = useSelector(drugSelector);
    const accountHasInsurance = useSelector(accountHasInsuranceSelector);
    const isCaliforniaUser = useSelector(accountIsCaliforniaUserSelector);
    const profileObject = useSelector(accountProfileSelector);
    const autoRefillToggleBusy = useSelector(medicineCabinetAutoRefillToggleBusySelector);
    const isOnDemandPlan = useSelector(membershipIsOnDemandSelector);
    const { getRxSubStatus } = useRxSubStatus(fullRxItem);
    const cardOrderShippingAddress = useSelector(cartOrderShippingAddressSelector);
    const defaultAddress = useSelector(accountDefaultAddressSelector);

    const zipcode = cardOrderShippingAddress?.zipcode || defaultAddress?.zipcode || '';

    const price = useMemo(() => {
        return findPriceMatch(rxNumber, zipcode, drugDiscountPrices);
    }, [rxNumber, zipcode, drugDiscountPrices]);

    const rxDisplayStatuses = useMemo(() => {
        return statuses.filter((item) => {
            return item.displayType === 'RX';
        });
    }, [statuses]);

    const orderDisplayStatuses = useMemo(() => {
        return statuses.filter((item) => {
            return item.displayType === 'ORDER';
        });
    }, [statuses]);

    const rxNextRefillStatuses = useMemo(() => {
        return statuses.filter((item) => {
            return item.displayType === 'NEXT_REFILL';
        });
    }, [statuses]);

    const rxAutoRefillEnabled = useMemo(() => {
        return fullRxItem.autoRefillEnabled;
    }, [fullRxItem.autoRefillEnabled]);

    const handleAutoRefillInCard = useCallback(
        (value: boolean, isRenew?: boolean) => {
            if (autoRefillToggle) {
                autoRefillToggle(rxNumber, rxSeqNum, value, isRenew);
            }
        },
        [autoRefillToggle, rxNumber, rxSeqNum]
    );

    /*
     * Refresh the medicine cabinet and show the success modal after a prescription
     * is cancelled.
     */
    const showSuccessModal = () => {
        const successModalContent = (
            <BirdiModalContent
                icon={'success'}
                title={t('components.prescriptionCardModal.cancelRx.successTitle')}
                body={t('components.prescriptionCardModal.cancelRx.successBody')}
            />
        );

        // Update prescriptions.
        dispatch(medicineCabinetGetAllPrescriptions({ showNewRxModal: false }));

        // Show the success modal.
        dispatch(
            openModal({
                showClose: true,
                bodyContent: successModalContent,
                ctas: [
                    {
                        label: t('components.prescriptionCardModal.cancelRx.gotIt'),
                        variant: 'primary',
                        onClick: () => dispatch(closeModal({}))
                    }
                ]
            })
        );
    };

    // Show the error modal if a prescription cancellation fails.
    const showErrorModal = () => {
        const errorModalContent = (
            <BirdiModalContent
                icon={'alert'}
                title={t('components.prescriptionCardModal.cancelRx.errorTitle')}
                body={t('components.prescriptionCardModal.cancelRx.errorBody', {
                    prescriptionName: prescriptionName
                })}
            />
        );

        dispatch(
            openModal({
                showClose: true,
                bodyContent: errorModalContent,
                ctas: [
                    {
                        label: t('components.prescriptionCardModal.cancelRx.tryAgain'),
                        variant: 'primary',
                        onClick: dispatchCancellation,
                        async: true
                    },
                    {
                        label: t('components.prescriptionCardModal.cancelRx.nevermind'),
                        variant: 'ghost',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ]
            })
        );
    };

    /*
     * Dispatch a prescription cancellation. This is called both by the initial
     * cancel modal and in the error modal if/when the user wants to try again.
     * */
    const dispatchCancellation = () => {
        // First set the busy state on the modal so that we get the spinner.

        // Then dispatch the cancellation.
        dispatch(setBusyModal(true));
        dispatch(
            cancelPrescriptionRoutine.trigger({
                rxNumber: rxNumber,
                onSuccess: showSuccessModal,
                onFailure: showErrorModal
            })
        );
    };

    // Handle the click to cancel the prescription.
    const handleCancelRxClick = () => {
        const cancelRxModalContent = (
            <BirdiModalContent
                icon={'alert'}
                title={t('components.prescriptionCardModal.cancelRx.title')}
                body={t('components.prescriptionCardModal.cancelRx.body', {
                    prescriptionName: prescriptionName,
                    phoneNumber: getPhoneNumber({ isEnd: true })
                })}
            />
        );

        dispatch(
            openModal({
                showClose: true,
                bodyContent: cancelRxModalContent,
                ctas: [
                    {
                        label: t('components.prescriptionCardModal.cancelRx.submit'),
                        variant: 'primary',
                        onClick: dispatchCancellation,
                        async: true
                    },
                    {
                        label: t('components.prescriptionCardModal.cancelRx.nevermind'),
                        variant: 'ghost',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ]
            })
        );
    };

    const orderSubStatus = useMemo(() => {
        return getRxSubStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullRxItem.rxSubStatus]);

    const isOrderSubStatusProcessing = useMemo<boolean>(() => {
        return fullRxItem.rxSubStatus?.CurrentStep === CURRENT_STEP_DESCRIPTION.PROCESSING;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullRxItem.rxSubStatus]);

    const orderSubStatusLoading = useMemo(() => {
        return fullRxItem.rxSubStatus?.loading;
    }, [fullRxItem.rxSubStatus]);

    const rxCardFootNote = useMemo(() => {
        if (footNote) {
            return footNote;
        } else if (orderSubStatus.subStatus && orderSubStatus.footNote) {
            return orderSubStatus.footNote;
        }
        return '';
    }, [footNote, orderSubStatus.footNote, orderSubStatus.subStatus]);

    const isPriceVisible = useMemo(() => {
        return (
            (!accountHasInsurance || planAlias === 'BRD01' || planAlias === 'BRD02') &&
            !!price &&
            price !== 'NA' &&
            Number(price) !== 0
        );
    }, [accountHasInsurance, planAlias, price]);

    const handleInfoClick = useCallback(
        (isTrackOrder: boolean) => {
            dispatch(
                openModal({
                    showClose: true,
                    className: `prescription-card-modal prescription-card-modal-status-${(orderStatus || 'unknown')
                        .toLowerCase()
                        .replace(/ /g, '_')}`,
                    bodyContent: (
                        <PrescriptionCardModal
                            fullRxItem={fullRxItem}
                            prescriptionName={prescriptionName}
                            orderStatus={orderStatus}
                            orderSubStatus={orderSubStatus.orderStatus}
                            refillsLeft={refillsLeft}
                            rxNumber={rxNumber}
                            rxDisplayStatuses={rxDisplayStatuses}
                            orderDisplayStatuses={orderDisplayStatuses}
                            ctas={ctas}
                            isTrackOrder={isTrackOrder}
                        />
                    ),
                    size: 'lg',
                    ctas: []
                })
            );

            let priceValue = 0;

            if (isPriceVisible) {
                priceValue = Number(formatPrice(price).replace('$', ''));
            }

            const item: ViewItemType = {
                rxNumber: rxNumber,
                name: prescriptionName,
                variant: fullRxItem.fillQuantity,
                price: priceValue
            };

            TrackViewItem(item);
        },
        [
            ctas,
            dispatch,
            fullRxItem,
            orderDisplayStatuses,
            orderStatus,
            orderSubStatus.orderStatus,
            prescriptionName,
            price,
            refillsLeft,
            rxDisplayStatuses,
            rxNumber,
            isPriceVisible
        ]
    );

    const isOrderedStatus = useMemo(() => {
        const statusesToBeVisible = ['ORDERED', 'SHIPPED'];
        return statusesToBeVisible.includes(orderStatus);
    }, [orderStatus]);

    const isOrderStatusVisible = useMemo(() => {
        return isOrderedStatus || rxCardFootNote !== '';
    }, [isOrderedStatus, rxCardFootNote]);

    const detailsWithPrice = useMemo(() => {
        if (!isPriceVisible) return details;

        return [
            ...details,
            {
                detail: (
                    <>
                        {t('components.prescriptionCard.cashPrice')}: <strong>{formatPrice(price)}</strong>
                    </>
                )
            }
        ];
    }, [price, details, isPriceVisible, t]);

    const showRefillsLeft = useMemo(() => {
        const refillStatus: string[] = [
            'REFILL_AVAILABLE',
            'IN_CART',
            'ORDERED',
            'REFILL_TOO_SOON',
            'EXPIRED',
            'SHIPPED',
            'OUT_OF_REFILLS'
        ];

        return refillStatus.includes(orderStatus as string);
    }, [orderStatus]);

    const autoRefillEligibleStatus = useMemo(() => {
        const refillStatus: string[] = ['NOT_ON_FORMULARY', 'PENDING'];

        return !refillStatus.includes(orderStatus as string);
    }, [orderStatus]);

    /**
     * The Birdi Select Badge requires specific validation based on the user's plan to ensure it
     * is displayed correctly according to the prescription response, not discount prices.
     * Additional logic needs to be implemented for this purpose.
     */
    const userPlanAlias = profileObject?.planAlias;
    const familyPlansMap: Record<string, string> = useSelector(accountFamilyPlansMapSelector);
    const isCaregiver = useSelector(accountProfilIsCaregiverSelector);
    const epostPatientNum: string = fullRxItem?.epostPatientNum;
    const planAllowsAutoRefill = profileObject ? hasPlanAutoRefillFlag(epostPatientNum, profileObject) : false;

    const isBirdiSelectVisible = useMemo(() => {
        const isMembershipPlanAlias = isCaregiver
            ? Object.keys(familyPlansMap).length > 0 && familyPlansMap[epostPatientNum] === 'BRD02' && !isOnDemandPlan
            : userPlanAlias === 'BRD02' && !isOnDemandPlan;

        return isMembershipPlanAlias && isBirdiSelect;
    }, [familyPlansMap, epostPatientNum, userPlanAlias, isBirdiSelect, isCaregiver]);

    const prescriptionsCardProps = {
        autoRefillEligibleStatus,
        autoRefillToggleBusy,
        ctas,
        details: detailsWithPrice,
        fullRxItem,
        handleAutoRefillInCard,
        handleCancelRxClick,
        handleInfoClick,
        isAddingToCart,
        isBirdiSelect: isBirdiSelectVisible,
        isCaliforniaUser,
        isCollapsed,
        isOrderStatusVisible,
        isOrderSubStatusProcessing,
        isOrderedStatus,
        orderDisplayStatuses,
        orderStatus,
        orderSubStatus,
        orderSubStatusLoading,
        planAllowsAutoRefill,
        prescriptionName,
        refillsLeft,
        rxAutoRefillEnabled,
        rxCardFootNote,
        rxDisplayStatuses,
        rxNextRefillStatuses,
        showRefillsLeft
    };

    return (
        <>
            <PrescriptionCardMobile className="d-md-none" {...prescriptionsCardProps} />
            <PrescriptionCardDesktop className="d-none d-md-flex" {...prescriptionsCardProps} />
        </>
    );
};

export default PrescriptionCard;
