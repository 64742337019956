import React from 'react';
import { CircleCheckProps } from './types';
import './index.style.scss';
import SuccessSvg from 'assets/icons/success.svg';

export const CircleCheck: React.FC<CircleCheckProps> = () => {
    return (
        <div className="circle-check__container">
            <img src={SuccessSvg} width={12} alt="success-icon" />
        </div>
    );
};
